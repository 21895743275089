<template>
  <v-scale-transition>
    <v-list-item v-if="isVisible" @click="handleClick">
      <v-card :color="color" class="pa-2">
        <v-row no-gutters align="center">
          <v-col cols="auto" class="mr-3">
            <component :is="icon" />
          </v-col>
          <v-col>
            <v-card-title class="text-subtitle-1 font-weight-bold pa-0">
              {{ name }}
            </v-card-title>
            <v-card-subtitle class="pa-0 text-caption">
              {{ type }}
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-card>
    </v-list-item>
  </v-scale-transition>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: 'primary',
  },
  type: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    default: 'mdi-card',
  },
});

const emit = defineEmits(['remove', 'showMoreInfo']);

const isVisible = ref(true);

const handleClick = () => {
  isVisible.value = false;
  emit('remove');
};
</script>

<style scoped>
.v-list-item {
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.v-list-item:hover {
  opacity: 0.8;
  transform: translateY(-2px);
}

.v-card {
  transition: box-shadow 0.3s ease;
}

.v-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}
</style>