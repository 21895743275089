<template>
  <v-app>
    <v-container>
      <v-btn @click="addRandomItem" class="mt-4 mr-2">隨機添加項目</v-btn>
      <v-btn @click="removeRandomItem" class="mt-4">隨機刪除項目</v-btn>
      <v-list>
        <TransitionGroup name="list">
          <v-list-item
            v-for="item in items"
            :key="item.id"
            :title="item.title"
            @click="removeItem(item.id)"
          ></v-list-item>
        </TransitionGroup>
      </v-list>
    </v-container>
  </v-app>
</template>

<script setup>
import { ref } from 'vue'

const items = ref([
  { id: 1, title: '項目 1' },
  { id: 2, title: '項目 2' },
  { id: 3, title: '項目 3' },
])

let nextId = 4

const addRandomItem = () => {
  const randomTitle = `隨機項目 ${Math.floor(Math.random() * 1000)}`
  items.value.push({
    id: nextId++,
    title: randomTitle,
  })
}

const removeItem = (id) => {
  const index = items.value.findIndex(item => item.id === id)
  if (index !== -1) {
    items.value.splice(index, 1)
  }
}

const removeRandomItem = () => {
  if (items.value.length > 0) {
    const randomIndex = Math.floor(Math.random() * items.value.length)
    items.value.splice(randomIndex, 1)
  }
}
</script>

<style>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>