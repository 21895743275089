<template>
  <div ref="pixiContainer"></div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { Application, Container, Graphics } from 'pixi.js';

const pixiContainer = ref(null);
let app;

onMounted(async () => {
  if (pixiContainer.value) {
    app = new Application();

    await app.init({ background: '#1099bb', resizeTo: pixiContainer.value });

    pixiContainer.value.appendChild(app.canvas);

    const world = new Container();
    app.stage.addChild(world);

    const hexGrid = new Container();
    world.addChild(hexGrid);

    // 六角形的尺寸
    const hexSize = 50;
    const horizontalSpacing = hexSize * Math.sqrt(3);
    const verticalSpacing = hexSize * 3 / 2;

    // 生成隨機顏色
    function getRandomColor() {
      return Math.floor(Math.random() * 0xFFFFFF);
    }

    // 繪製六角形函數
    function drawHexagon(x, y) {
      const hexagon = new Graphics();
      hexagon.lineStyle(2, 0xFFFFFF, 1);
      hexagon.beginFill(getRandomColor());  // 使用隨機顏色
      for (let i = 0; i < 6; i++) {
        const angle = 2 * Math.PI / 6 * i - Math.PI / 6; // 旋轉30度
        const xPos = x + hexSize * Math.cos(angle);
        const yPos = y + hexSize * Math.sin(angle);
        if (i === 0) {
          hexagon.moveTo(xPos, yPos);
        } else {
          hexagon.lineTo(xPos, yPos);
        }
      }
      hexagon.closePath();
      hexagon.endFill();
      return hexagon;
    }

    // 繪製 50x50 的六角形網格
    for (let row = 0; row < 50; row++) {
      for (let col = 0; col < 50; col++) {
        const x = col * horizontalSpacing + (row % 2) * (horizontalSpacing / 2);
        const y = row * verticalSpacing;
        const hexagon = drawHexagon(x, y);
        hexGrid.addChild(hexagon);
      }
    }

    // 將網格置中
    hexGrid.x = (app.screen.width - hexGrid.width) / 2;
    hexGrid.y = (app.screen.height - hexGrid.height) / 2;

    // 拖動功能（保持不變）
    let isDragging = false;
    let dragStart = { x: 0, y: 0 };

    app.view.style.cursor = 'move';

    app.view.addEventListener('pointerdown', (event) => {
      isDragging = true;
      dragStart.x = event.clientX - world.x;
      dragStart.y = event.clientY - world.y;
      app.view.style.cursor = 'grabbing';
    });

    app.view.addEventListener('pointermove', (event) => {
      if (isDragging) {
        world.x = event.clientX - dragStart.x;
        world.y = event.clientY - dragStart.y;
      }
    });

    app.view.addEventListener('pointerup', () => {
      isDragging = false;
      app.view.style.cursor = 'move';
    });

    app.view.addEventListener('pointerleave', () => {
      isDragging = false;
      app.view.style.cursor = 'move';
    });
  }
});
</script>