<template>
  <v-card 
    class="ma-2"
    ripple="false"
    @click="dialog = true"
  >
    <v-card-item>
      <template v-slot:prepend>
        <component :is="data.icon" size="50"/>
      </template>
      <v-card-title>{{ data.name }}</v-card-title>
    </v-card-item>
    <v-card-text>
      <v-row align="center" no-gutters>
        <v-col cols="auto" class="mr-2">
          <v-chip>{{ data.level }}</v-chip>
        </v-col>
        <v-col>
          <v-progress-linear
            :model-value="data.progress"
            color="primary"
            height="20"
            rounded
            class="my-2"
          >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          color="primary"
          title="詳細信息"
        >
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <h3>技能</h3>
          <v-list>
            <v-list-item v-for="(skill, index) in data.skill" :key="index">
              <template v-slot:prepend>
                <v-icon :color="skill.complete ? 'green' : 'grey'">
                  {{ skill.complete ? 'mdi-check-circle' : 'mdi-circle-outline' }}
                </v-icon>
              </template>
              <v-list-item-title>{{ skill.skillName }}</v-list-item-title>
              <v-list-item-subtitle>{{ skill.introduce }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>

          <v-divider class="my-4"></v-divider>

          <h3>任務</h3>
          <v-list>
            <v-list-item v-for="(quest, index) in data.quest" :key="index">
              {{ quest }}
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const dialog = ref(false);
</script>