import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createVuetify } from 'vuetify'
import 'vuetify/styles'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as LucideIcons from 'lucide-vue-next'
//toast
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const vuetify = createVuetify({
  components,
  directives,
})

const options = {
  // You can set your default options here
};

const app = createApp(App)
app.use(router)
app.use(vuetify)
app.use(Toast, options)

// 註冊所有 Lucide 圖標
Object.entries(LucideIcons).forEach(([name, component]) => {
  app.component(name, component)
})

app.mount('#app')