<template>
  <v-container>
    <v-row>
      <v-col cols="9">
        <ProgressBar v-model:progress="progressValue" />
        <v-btn @click="drawCards" class="mt-4" color="primary" :loading="isLoading">
          抽卡
        </v-btn>
        <v-alert v-if="error" type="error" class="mt-4">
          {{ error }}
        </v-alert>
        <ConfirmationCard
          v-if="showConfirmationCard"
          :card="cardToConfirm"
          class="mt-4"
          @discard="discardCard"
        />
        
      </v-col>
      <v-col cols="3">
        <v-list>
          <transition-group name="list">
            <CardItem
              v-for="item in drawResults"
              :key="item.id"
              :name="item.name"
              :color="item.color"
              :icon="item.icon"
              :type="item.type"
              @remove="showConfirmation(item)"
              class="full-width"
            />
          </transition-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, inject } from 'vue'
import ProgressBar from '../components/ProgressBar.vue'
import CardItem from '../components/EventResultCard.vue'
import ConfirmationCard from '../components/ConfirmationCard.vue'
import { useToast } from "vue-toastification";

const API_URL = 'http://localhost:3000';
const progressValue = ref(0)
const drawResults = ref([])
const isLoading = ref(false)
const error = ref(null)
const showConfirmationCard = ref(false)
const cardToConfirm = ref(null)
const toast = useToast();

function addNotification(text, type){
  toast.success(text);
}

const drawCards = async () => {
  isLoading.value = true
  error.value = null
  
  drawResults.value = []

  try {
    const response = await fetch(`${API_URL}/draw`);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    
    const { randomItems, progressBarValue } = await response.json();

    drawResults.value = randomItems.map((item, index) => ({
      ...item,
      id: `${item.id}-${index}`
    }));

    progressValue.value = (progressValue.value + progressBarValue) % 100;
    addNotification(`進度增加到 ${progressValue.value}%`, 'info');
  } catch (err) {
    console.error('抽卡失敗：', err);
    error.value = `抽卡失敗：${err.message}`;
    addNotification('抽卡失敗，請稍後再試', 'error');
  } finally {
    isLoading.value = false;
  }
}

const showConfirmation = (card) => {
  cardToConfirm.value = card
  showConfirmationCard.value = true
}

const discardCard = () => {
  const index = drawResults.value.findIndex(item => item.id === cardToConfirm.value.id)
  if (index !== -1) {
    drawResults.value.splice(index, 1)
  }
  showConfirmationCard.value = false
  cardToConfirm.value = null
  addNotification('已捨棄卡片', 'info')
}
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>