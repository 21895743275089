<template>
  <v-card
    color="white"
    append-icon="mdi-open-in-new"
  >
    <v-card-title>白樺木</v-card-title>
    <v-card-text>
      您確定要移除這張卡片嗎？
      <br>
      卡片內容：{{ card.text }}
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="!showEndButton" color="primary" @click="startTimer">開始計時</v-btn>
      <v-btn v-else color="error" @click="$emit('discard')">結束</v-btn>
    </v-card-actions>
    <v-progress-linear
      v-model="progress"
      color="amber"
      height="10"
    ></v-progress-linear>
  </v-card>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  card: {
    type: Object,
    required: true
  }
});
const emit = defineEmits(['discard']);

const progress = ref(100);
const showEndButton = ref(false);
let timer;

const startTimer = () => {
  if (timer) return; // Prevent multiple timers

  timer = setInterval(() => {
    if (progress.value > 0) {
      progress.value -= 2;
    } else {
      clearInterval(timer);
      showEndButton.value = true;
    }
  }, 100);
};
</script>
