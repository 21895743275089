<template>
  <Layout ref="layoutRef">
    <router-view></router-view>
  </Layout>
</template>

<script setup>
import { ref, provide } from 'vue'
import Layout from './components/Layout.vue'

const layoutRef = ref(null)

// 提供 addNotification 方法给所有子组件
provide('addNotification', (message, type, duration) => {
  layoutRef.value.addNotification(message, type, duration)
})
</script>