<template>
  <v-container>
    <v-row>
      <v-col v-for="item in info" :key="item.id">
        <Block
          :data="item"
          :isExpanded="expandedId === item.id"
          @toggle="toggleExpand(item.id)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from 'vue'
import Block from '../components/ProfessionBlock.vue'

const info = ref([
	{
		"name": "移動",
		"progress": 35, 
		"level": 3, 
		"maxLevel": 12,
		icon: "Footprints",
		"skill": [
			{
				"complete": true,
				"skillName": "移動加速I",
				"introduce": "獲得 10% 移動速度"
			},
			{
				"complete": true,
				"skillName": "移動加速II",
				"introduce": "獲得 20% 移動速度"
			},
			{
				"complete": false,
				"skillName": "移動加速III",
				"introduce": "獲得 30% 移動速度"
			},
		],
		"quest": [
			"移動 100 格",
			"製作傳送卷軸",
			"搭乘礦車"
		],
		"id": "MOVE"
	},
	{
		"name": "探索",
		"progress": 35, 
		"level": 3, 
		"maxLevel": 12,
		icon: "Telescope",
		"skill": [
			{
				"complete": true,
				"skillName": "移動加速I",
				"introduce": "獲得 10% 移動速度"
			},
			{
				"complete": true,
				"skillName": "移動加速II",
				"introduce": "獲得 20% 移動速度"
			},
			{
				"complete": false,
				"skillName": "移動加速III",
				"introduce": "獲得 30% 移動速度"
			},
		],
		"quest": [
			"移動 100 格",
			"製作傳送卷軸",
			"搭乘礦車"
		],
		"id": "DISCOVER"
	},
	{
		"name": "戰鬥",
		"progress": 35, 
		"level": 3, 
		"maxLevel": 12,
		icon: "Swords",
		"skill": [
			{
				"complete": true,
				"skillName": "移動加速I",
				"introduce": "獲得 10% 移動速度"
			},
			{
				"complete": true,
				"skillName": "移動加速II",
				"introduce": "獲得 20% 移動速度"
			},
			{
				"complete": false,
				"skillName": "移動加速III",
				"introduce": "獲得 30% 移動速度"
			},
		],
		"quest": [
			"移動 100 格",
			"製作傳送卷軸",
			"搭乘礦車"
		],
		"id": "BATTLE"
	},
	{
		"name": "製造",
		"progress": 35, 
		"level": 3, 
		"maxLevel": 12,
		icon: "Bolt",
		"skill": [
			{
				"complete": true,
				"skillName": "移動加速I",
				"introduce": "獲得 10% 移動速度"
			},
			{
				"complete": true,
				"skillName": "移動加速II",
				"introduce": "獲得 20% 移動速度"
			},
			{
				"complete": false,
				"skillName": "移動加速III",
				"introduce": "獲得 30% 移動速度"
			},
		],
		"quest": [
			"移動 100 格",
			"製作傳送卷軸",
			"搭乘礦車"
		],
		"id": "BUILD"
	},
]);

const expandedId = ref(null);

const toggleExpand = (id) => {
  expandedId.value = expandedId.value === id ? null : id;
};
</script>