import { createRouter, createWebHistory } from 'vue-router'
import Discover from './views/Discover.vue'
import Profile from './views/Profile.vue'
import Settings from './views/Settings.vue'
import Profession from './views/Profession.vue'
import Social from './views/Social.vue'
import Collection from './views/Collection.vue'



const routes = [
  { path: '/', component: Discover },
  { path: '/Discover', component: Discover },
  { path: '/profile', component: Profile },
  { path: '/settings', component: Settings },
  { path: '/profession', component: Profession },
  { path: '/social', component: Social },
  { path: '/collection', component: Collection },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router