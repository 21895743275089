<template>
  <div>
    <v-progress-linear
      :model-value="progressValue"
      color="primary"
      height="20"
      striped
    >
      <template v-slot:default="{ value }">
        <strong>{{ Math.ceil(value) }}%</strong>
      </template>
    </v-progress-linear>
  </div>
</template>

<script setup>
import { computed } from 'vue'

// 定義 props
const props = defineProps({
  progress: {
    type: Number,
    default: 0,
    validator: (value) => value >= 0 && value <= 100
  }
})

// 計算屬性確保進度值在 0-100 之間
const progressValue = computed(() => {
  return Math.min(100, Math.max(0, props.progress))
})

// 如果還需要 incrementProgress 方法，可以保留並修改如下
const incrementProgress = () => {
  // 發出事件通知父組件更新進度
  emit('update:progress', Math.min(100, props.progress + 10))
}

// 定義要暴露給父組件的方法
defineExpose({ incrementProgress })

// 定義自定義事件
const emit = defineEmits(['update:progress'])
</script>