<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :rail="rail"
      permanent
    >
      <v-sheet 
        :height="90" 
        color="blue"
      ></v-sheet>
      <ProfileItem/>
      <v-divider class="mt-4"></v-divider>
      <v-list>
        <NavListItem 
          to="/"
          icon="Footprints"
          title="探索"
        />
        <NavListItem 
          to="/profile"
          icon="Backpack"
          title="背包"
          tag="5"
        />
        <NavListItem 
          to="/profession"
          icon="BookMarked"
          title="專業"
          tag="25"
        />
        <NavListItem 
          to="/collection"
          icon="FolderCheck"
          title="成就"
          tag="1"
        />
        <NavListItem 
          to="/social"
          icon="Globe"
          title="社交"
          tag="25"
        />
        <NavListItem 
          to="/settings"
          icon="Settings"
          title="設定"
        />
      </v-list>
    </v-navigation-drawer>

    <v-app-bar>
      <v-btn @click="toggleRail">
        <Menu />
      </v-btn>
      <v-app-bar-title>
        Wynn叢林中部 (134, 502)
        <v-chip
          color="red"
          class="ml-2"
        >危險</v-chip>
      </v-app-bar-title>
      
      <v-card
        class="mr-4"
        subtitle="2147483647"
        link
        flat
      >
        <template v-slot:prepend>
          <Coins />
        </template>
      </v-card>
      <v-card
        class="mr-4"
        subtitle="2147483647"
        link
        flat
      >
        <template v-slot:prepend>
          <Gem />
        </template>
      </v-card>
      <v-badge :content="88" class="mr-8">
        <ScrollText/>
      </v-badge>
    </v-app-bar>

    <v-main>
      <slot></slot>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref } from 'vue'
import NavListItem from './NavListItem.vue'
import { ScrollText } from 'lucide-vue-next'
import ProfileItem from './LayoutProfile.vue'

const drawer = ref(true)
const rail = ref(false)

const toggleRail = () => {
  rail.value = !rail.value
}

</script>